import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Step2 from "../views/Step2.vue";
import Step3 from "../views/Step3.vue";
import Step4 from "../views/Step4.vue";
import Step5 from "../views/Step5.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/step2",
    name: "step2",
    component: Step2
  },
  {
    path: "/step3",
    name: "step3",
    component: Step3
  },
  {
    path: "/step4",
    name: "step4",
    component: Step4
  },
  {
    path: "/step5",
    name: "step5",
    component: Step5
  },
  {
    path: "/booknow",
    name: "booknow",
    component: () => import("../views/BookNow.vue")
  },
  {
    path: "/success",
    name: "success",
    component: () => import("../views/Success.vue")
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/contact-us",
    name: "contact-us",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ContactUs.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
