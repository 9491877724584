import axios from "axios";

import store from "@/store/";

import HxHost from "@/plugins/HxHost";
// import router from "@/router";

export default function(requestObject) {
  let baseUrl = HxHost();

  /* in case we're not using HX api; for test purposes only */
  if (requestObject.url.indexOf("http") === 0) {
    baseUrl = "";
  }

  const filteredUrl = requestObject.url;
  const url = `${baseUrl}${filteredUrl}`;

  let params = {};
  if (requestObject.url !== "/usertoken/lite.js") {
    params = {
      key: store.getters.hxInfo.key,
      token: store.getters.hxInfo.token,
      ABTANumber: store.getters.hxInfo.ABTANumber,
      Password: store.getters.hxInfo.password
    };
  }

  if (requestObject.params) {
    Object.assign(params, requestObject.params);
  }

  return axios({
    method: requestObject.method,
    url: url,
    params: params
  })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      if (err.response) {
        const status = err.response.status;
        const data = err.response.data;

        handleError(status, data);

        throw { response: data };
      }
    });

  //FIXME: The place to unload the loading :)
  // .finally(() => {
  //   store.commit(uiTypes.IS_LOADING, false);
  // });

  function handleError(statusCode) {
    switch (statusCode) {
      case 401:
        console.log("Unauthorized!");
        break;
      default:
        console.log("Error " + statusCode);
    }
  }
}
