<template>
  <div class="content">
    <div class="steps step4">
      <div
        v-for="(room, index) in Object.values(rooms)"
        v-bind:key="room.label"
        class="row"
      >
        <label>{{ room.label }}</label>
        <carousel
          :swiperSlideArray="Object.values(room.variants)"
          :swiperClass="['products', 'swiper' + index]"
          :swiperUniqueClass="'swiper' + index"
          :swiperSlideClass="['product']"
          :swiperSlideObjectKey="'code'"
          :swiperPageOrder="index"
          :swiperSlideWidth="300"
          v-slot="{ slotData }"
        >
          <!-- <li v-for="variant in room.variants" v-bind:key="variant.code"> -->
          <div
            class="product-link"
            v-on:click="
              getRoomType(
                slotData.name,
                slotData.code,
                slotData.sleeps,
                slotData.adults,
                slotData.children
              )
            "
          >
            <img :src="'./roomTypes/room' + slotData.code + '.svg'" />
          </div>
          <!-- </li> -->
        </carousel>
      </div>
    </div>
  </div>
</template>

<script>
import Carousel from "../carousel.vue";

export default {
  name: "step4",
  components: {
    Carousel
  },
  data() {
    return {
      imgURL: "../../assets/img/roomTypes/room",
      rooms: {
        onePerson: {
          label: "One Person",
          slug: "one-person",
          variants: {
            variant1: {
              name: "Single Room",
              sleeps: "1 adult",
              code: "S10",
              adults: 1,
              children: 0
            }
          }
        },
        twoPeople: {
          label: "Two People",
          slug: "two-people",
          variants: {
            variant1: {
              name: "Double Room",
              sleeps: "2 adults",
              code: "D20",
              adults: 2,
              children: 0
            },
            // variant2: {
            //   name: "Twin Room",
            //   sleeps: "2 adults",
            //   code: "T20"
            // },
            variant2: {
              name: "Twin Room",
              sleeps: "1 adult, 1 child",
              code: "T11",
              adults: 1,
              children: 1
            }
          }
        },
        threePeople: {
          label: "Three People",
          slug: "three-people",
          variants: {
            variant1: {
              name: "Triple Room",
              sleeps: "2 adults, 1 child",
              code: "T21",
              adults: 2,
              children: 1
            },
            variant2: {
              name: "Triple Room",
              sleeps: "1 adult, 2 children",
              code: "T12",
              adults: 1,
              children: 2
            },
            variant3: {
              name: "Triple Room",
              sleeps: "3 Adults",
              code: "T30",
              adults: 3,
              children: 0
            }
          }
        },
        fourPeople: {
          label: "Four People",
          slug: "four-people",
          variants: {
            variant1: {
              name: "Quad Room",
              sleeps: "2 adults, 2 children",
              code: "F22",
              adults: 2,
              children: 2
            },
            variant2: {
              name: "Quad Room",
              sleeps: "1 adult, 3 children",
              code: "F13",
              adults: 1,
              children: 3
            },
            variant3: {
              name: "Quad Room",
              sleeps: "3 adults, 1 child",
              code: "F31",
              adults: 3,
              children: 1
            }
          }
        },
        fivePeople: {
          label: "Five People",
          slug: "five-people",
          variants: {
            variant1: {
              name: "Family Room",
              sleeps: "2 adults, 3 children",
              code: "F23",
              adults: 2,
              children: 3
            },
            variant2: {
              name: "Family Room",
              sleeps: "3 adults, 2 children",
              code: "F32",
              adults: 3,
              children: 2
            }
          }
        }
      }
    };
  },
  methods: {
    getRoomType(roomName, roomCode, sleeps, adults, children) {
      this.$store.commit({
        type: "setRoomType",
        roomName: roomName,
        roomCode: roomCode,
        random: roomName,
        sleeps: sleeps,
        adults: adults,
        children: children
      });

      /* updating local storage data */
      localStorage.setItem(
        `bookingParams${btoa(window.location.host)}`,
        JSON.stringify(this.$store.getters.bookingParams)
      );
      this.$router.push({ name: "step5" });
    }
  }
};
</script>

<style scoped lang="sass">
@import "../../assets/sass/global.sass"
.content
  ul
    li
      background: linear-gradient(135deg, rgba($accent1,0.3) 0%,rgba($accent2,0) 100%)
</style>
