<template>
  <div :class="type">
    <productInfo
      v-if="showProductInfo"
      :product="selectedProduct"
      @booknow="booknow"
    />
    <label>{{ label }}</label>
    <!-- <div v-if="true" class="loading"> -->
    <div v-if="loading[type]" class="loading">
      <img src="@/assets/img/loading.svg" />
    </div>
    <div v-else class="row">
      <!-- CAROUSEL -->
      <carousel
        v-slot="{ slotData }"
        :swiperSlideArray="getStock[type]"
        :swiperClass="['products', 'swiper' + index]"
        :swiperUniqueClass="'swiper' + index"
        :swiperSlideClass="['product']"
        :swiperSlideObjectKey="'Code'"
        :step5id="'Code'"
        :swiperPageOrder="index"
        :swiperSlideWidth="300"
      >
        <div class="product-link" v-on:click="activateProductInfo(slotData)">
          <h3>{{ slotData.Name }}</h3>
          <h4>
            &pound;{{
              slotData.NonDiscPrice ? slotData.NonDiscPrice.toFixed(2) : "n/a"
            }}
          </h4>
        </div>
      </carousel>
    </div>
  </div>
</template>

<script>
import Carousel from "@/components/carousel.vue";

import { mapState } from "vuex";
import productInfo from "@/components/productInfo.vue";
export default {
  name: "productRow",
  components: {
    productInfo,
    Carousel
  },
  props: {
    type: String,
    label: String,
    index: Number
  },
  data() {
    return {
      showProductInfo: false,
      selectedProduct: undefined
    };
  },
  computed: {
    ...mapState(["loading"]),
    loadingHotels() {
      return this.$store.state.loading.hotels;
    },
    getAirport() {
      return this.$store.getters.getAirport;
    },
    getStock() {
      return this.$store.getters.getStock[this.$store.getters.getAirport];
    }
  },
  methods: {
    activateProductInfo(product) {
      this.selectedProduct = product;
      this.showProductInfo = true;
    },
    booknow(productData) {
      this.$store.commit({
        type: "setSelectedProduct",
        productData: productData
      });

      /* updating local storage data */
      localStorage.setItem(
        `bookingParams${btoa(window.location.host)}`,
        JSON.stringify(this.$store.getters.bookingParams)
      );
      this.$router.push("booknow");
    }
  }
};
</script>

<style scoped lang="sass">
@import "@/assets/sass/global.sass"

.product
  h3
    font-size: 2rem
  h4
    font-size: 1.3rem

.content
  ul
    li,
    .product
      background: linear-gradient(135deg, rgba($accent1,0.3) 0%,rgba($accent2,0) 100%)
</style>
