import DetectEnvironment from "./DetectEnvironment.js";
// This function checks the hostname and returns the environment

export default function HxHost() {
  let env = DetectEnvironment();
  let hosts = {
    local: "https://api.holidayextras.co.uk/sandbox",
    staging: "https://api.holidayextras.co.uk/sandbox",
    production: "https://api.holidayextras.co.uk"
  };
  return hosts[env];
}
