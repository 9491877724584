<template>
  <header>
    <h2>Airport extras made easy...</h2>
    <div class="contact-nav">
      <a href="/contact-us" class="contact-us"> <span>Contact Us</span></a>
      <a href="tel:0800 083 2808" class="tel"
        ><img class="icon" src="../assets/img/icons/phone.svg" />
        <span>0800 083 2808</span></a
      >
    </div>
  </header>
</template>

<script>
export default {
  name: "topBar"
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
@import "../assets/sass/global.sass"
header
  backdrop-filter: blur(15px)
  width: 100%
  height: 7rem
  display: flex
  align-items: center
  position: fixed
  top: 0
  left: 0
  z-index: 2
  box-shadow: 1rem 1rem 2rem rgba(0,0,0,0.05)
  color: $white
  background: linear-gradient(to right, rgba($accent1,0.7), rgba($accent2,0.4))
  &:after
    content: ""
    position: absolute
    top: 7rem
    height: 1px
    width: 100%
    background: linear-gradient(to right, rgba($white, 0), rgba($white, 0.5), rgba($white, 0.7), rgba($white, 0.5), rgba($white, 0))
  &:before
    content: ""
    position: absolute
    top: 7rem
    left: 32.5rem
    height: 1px
    width: 10rem
    background: linear-gradient(to right, rgba($white, 0), rgba($white, 0.5), rgba($white, 1), rgba($white, 0.5), rgba($white, 0))
  h2
    color: $white
    font-weight: 400
    padding: 0 3rem 0 40.5rem
    font-size: 1.6rem
    margin: 0

  .contact-nav
    font-size: 1.6rem
    font-weight: 500
    padding: 0 3rem
    margin: 0 0 0 auto
    display: flex
    color: $white
    a
      margin: 0 0 0 auto
      display: flex
      color: $white

    span
      line-height: 2.4rem
    .icon
      width: 2.4rem
      margin-right: 1rem

  .contact-us
    padding: 0

  @media screen and (max-width: 964px) and (min-width: 769px)
    h2
      padding-left: 24rem
  .contact-nav
    padding-left: 0

    .contact-us
      padding-right: 3rem
</style>
