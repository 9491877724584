<template>
  <div id="app">
    <div class="hamburger-wrapper">
      <div>
        <img
          class="hamburger-button"
          :src="
            !sideBarStatus
              ? require('@/assets/img/icons/menu.svg')
              : require('@/assets/img/icons/x.svg')
          "
          @click="sideBarStatusMethod"
        />
      </div>
    </div>
    <top-bar
      :class="[
        { 'side-bar-open': sideBarStatus },
        'h2 top-bar mobile-nav-closed'
      ]"
    />
    <side-bar
      :class="[{ 'side-bar-open': sideBarStatus }, 'side-bar']"
      @set-status-false="setStatus"
    />
    <router-view
      id="home"
      :class="[{ 'side-bar-open': sideBarStatus }, 'mobile-nav-closed']"
    />

    <!-- Used this to easily see if the site is in sandbox mode or not -->
    <div
      v-if="showWatermark"
      style="
        display: block;
        position: fixed;
        bottom: 0;
        left: 0;
        background: #ffffff;
        padding: 2px 8px;
        font-weight: bold;
        font-size: 1rem;
      "
    >
      TEST MODE
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import topBar from "@/components/topBar.vue";
import sideBar from "@/components/sideBar.vue";
import { mapGetters } from "vuex";

import { getToken } from "@/api/holiday-extras/holiday-extra.js";

export default {
  components: {
    topBar,
    sideBar
  },
  data() {
    return {
      sideBarStatus: false,
      showWatermark: false,
      userToken: ""
    };
  },

  created() {
    // FIXME: Temporary fix for loosing store on refresh, remove after persited state!
    if (!this.bookingParams.airportCode) {
      const bookingParams = localStorage.getItem(
        `bookingParams${btoa(window.location.host)}`
      );
      if (bookingParams) {
        this.$store.commit({
          type: "setBookingParams",
          bookingParams: JSON.parse(
            localStorage.getItem(`bookingParams${btoa(window.location.host)}`)
          )
        });
      } else if (['step2', 'step3', 'step4', 'step5', 'booknow', 'success'].includes(
        this.$route.name
      )) {
        /* redirect only when accessing booking process page */
        this.$router.push("/");
      }
    }

    if (!localStorage.getItem(`usertoken${btoa(window.location.host)}`)) {
      getToken().then(response => {
        if (response.API_Reply.Token) {
          localStorage.setItem(
            `usertoken${btoa(window.location.host)}`,
            response.API_Reply.Token
          );
          this.userToken = response.API_Reply.Token;
        }
      });
    } else {
      this.userToken = localStorage.getItem(
        `usertoken${btoa(window.location.host)}`
      );
    }

    this.$store.commit({
      type: "setHXToken",
      token: this.userToken
    });
  },

  methods: {
    setEmailJS() {
      const emailjs = document.createElement("script");
      emailjs.setAttribute("src", "https://cdn.emailjs.com/dist/email.min.js");
      emailjs.async = true;
      document.head.appendChild(emailjs);
    },
    sideBarStatusMethod() {
      this.sideBarStatus = !this.sideBarStatus;
    },
    setStatus() {
      this.sideBarStatus = false;
    },
    addTestWaterMark() {
      this.showWatermark = this.$app.env !== "production";
    }
  },
  computed: {
    ...mapGetters(["bookingParams"])
  },
  mounted() {
    this.setEmailJS();
    this.addTestWaterMark();
  }
};
</script>

<style lang="sass">
@import "./assets/sass/global.sass"
*
  box-sizing: border-box
html
  font-size: 62.5%
body
  margin: 0
  background: linear-gradient(to right, $accent1, $accent2)
#app
  font-family: Gilroy, Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  color: #333333
  background: linear-gradient(to bottom, rgba($accent1, 0), rgba($accent1, 0), rgba($accent3, 0.5))
  height: 100vh

// PALETTE
$light-grey: #f5f5f5

// TYPOGRAPHY
h1, h2, h3, h4, h5, h6
  display: inline
a
  text-decoration: none
ul, ol
  list-style: none
  margin: 0
  padding: 0

//mobile op
.hamburger-wrapper
  height: 7rem;
  margin: auto;
  position: absolute;
  z-index: 4;
  display: flex;

  .hamburger-button
    cursor: pointer
    margin: 25px 35px;

    @media screen and (min-width: 768px)
      display: none;

// mobile view
@media screen and (max-width: $mobile-breakpoint)
  .mobile-nav-closed
    transition: all 0.2s ease;
    left: 0 !important;
    width: 100%;

    &.side-bar-open
      left: 100% !important;

  .side-bar
    transition: all 0.2s ease;
    width: 100% !important;
    left: -100% !important;

    &.side-bar-open
      left: 0 !important;

  .top-bar
    h2
      display: none;
</style>
