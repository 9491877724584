<template>
  <div class="content">
    <div class="steps step5">
      <product-row
        v-if="isHotels"
        type="hotels"
        label="Hotel only deals"
        :index="0"
      />

      <product-row
        v-if="isHotelsAndParking"
        type="hotelsandparking"
        label="Hotel and parking packages"
        :index="1"
      />

      <product-row
        v-if="isParking"
        type="parking"
        label="Parking only deals"
        :index="2"
      />

      <product-row
        v-if="isLounges"
        type="lounges"
        label="Airport lounges"
        :index="3"
      />

      <product-row
        v-if="isFastTrack"
        type="fasttracks"
        label="Fasttrack passes"
        :index="4"
      />
    </div>
  </div>
</template>

<script>
import {
  getHotels,
  getParkings,
  getLounges,
  getFastTrack
} from "@/api/holiday-extras/holiday-extra.js";
import productRow from "@/components/productRow.vue";
import { mapGetters } from "vuex";

export default {
  name: "step5",
  components: {
    productRow
  },

  data() {
    return {
      isHotels: true,
      isHotelsAndParking: true,
      isParking: true,
      isLounges: true,
      isFastTrack: true
    };
  },

  methods: {
    callHotels() {
      getHotels({
        Nights: this.bookingParams.stayingDays,
        ParkingDays: 0
      }).then(res => {
        if (res.API_Reply.Error) {
          this.isHotels = false;
          return;
        } else {
          this.setHotels(res.API_Reply.Hotel);
        }
      });
    },

    callHotelsAndParking() {
      getHotels({
        Nights: this.bookingParams.stayingDays,
        ParkingDays: this.bookingParams.stayingDays
      }).then(res => {
        if (res.API_Reply.Error) {
          this.isHotelsAndParking = false;
          return;
        } else {
          this.setHotelsAndParking(res.API_Reply.Hotel);
        }
      });
    },

    callParking() {
      getParkings().then(response => {
        if (response.API_Reply.Error) {
          this.isParking = false;
          return;
        } else {
          this.setParking(response.API_Reply.CarPark);
        }
      });
    },

    callLounges() {
      getLounges().then(response => {
        if (response.API_Reply.Error) {
          this.isLounges = false;
          return;
        }

        this.setLounges(response.API_Reply.Lounge);
      });
    },

    callFastTracks() {
      getFastTrack().then(response => {
        if (response.API_Reply.Error) {
          this.isFastTrack = false;
          return;
        } else {
          this.setFastTracks(response.API_Reply.FastTrack);
        }
      });
    },

    setHotels(hxData) {
      this.$store.commit({
        type: "setHotels",
        hxData: hxData
      });
    },

    setHotelsAndParking(hxData) {
      this.$store.commit({
        type: "setHotelsAndParking",
        hxData: hxData
      });
    },

    setParking(hxData) {
      this.$store.commit({
        type: "setParking",
        hxData: hxData
      });
    },

    setLounges(hxData) {
      this.$store.commit({
        type: "setLounges",
        hxData: hxData
      });
    },

    setFastTracks(hxData) {
      this.$store.commit({
        type: "setFastTracks",
        hxData: hxData
      });
    },

    resetLoading() {
      this.$store.commit({
        type: "resetLoading",
        loading: {
          hotels: true,
          hotelsandparking: true,
          parking: true,
          lounges: true,
          fasttracks: true
        }
      });
    }
  },
  computed: {
    ...mapGetters(["bookingParams"])
  },
  mounted() {
    this.callHotels();
    this.callHotelsAndParking();
    this.callParking();
    this.callLounges();
    this.callFastTracks();
  },
  beforeDestroy() {
    this.resetLoading();
  }
};
</script>

<style scoped lang="sass"></style>
