<template>
  <div class="moreInfo">
    <img
      class="close"
      src="../assets/img/icons/close.svg"
      v-on:click="closeMoreInfo"
    />
    <div class="text">
      <h1>All you need to know about the {{ hotelInfo.name }}</h1>
      <div id="div" v-html="hotelInfo.tripappintroduction"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "moreInfo",
  props: {
    hotelInfo: {}
  },
  methods: {
    closeMoreInfo() {
      this.$parent.showMoreInfo = false;
    }
  },
  mounted() {
    // console.log('HOTEL INFO: ', this.hotelInfo)
  }
};
</script>

<style lang="sass">
@import "../assets/sass/global.sass"
.close
  position: absolute
  top: 13rem
  right: 6rem
  opacity: 0.7
  width: 3rem
  height: 3rem
  cursor: pointer
  z-index: 9999
  &:hover
    opacity: 1
.moreInfo
  left: 37.5rem
  position: fixed
  top: 0
  right: 0
  bottom: 0
  // left: 38.5rem
  background: linear-gradient(to bottom, rgba($accent1, 1), rgba($accent2, 1))
  mask: linear-gradient(to right, rgba(0,0,0,95), rgba(0,0,0,0.8))
  shadow: 0 0 3rem rgba(0,0,0,0.4)
  z-index: 9999
  .text
    with: 100%
    margin-left: auto
    color: $white
    overflow: auto
    position: absolute
    top: 7rem
    bottom: 0
    right: 0
    left: 0
    padding: 6rem
    > div
      max-width: 64rem
    p, li
      font-size: 1.6rem
    ul
      margin: 1.5rem 0
      display: block
    h1
      margin: 0
      padding: 0
      margin: 0 0 4rem 0
      font-size: 3.6rem
      font-weight: 600
      display: block
      max-width: 64rem
    h2, h3, h5, h6
      font-size: 2.5rem
      margin-top: 4rem
      display: block
      font-weight: 600
</style>
