<template>
  <div class="home">
    <step2 />
  </div>
</template>

<script>
import step2 from "@/components/steps/step2.vue";

export default {
  components: {
    step2
  },
  methods: {}
};
</script>

<style lang="sass"></style>
