var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',[_c('h1',[_c('router-link',{attrs:{"to":"/"}},[_c('strong',[_vm._v("Hotels")]),_vm._v("24Seven")])],1),_c('ul',[(_vm.$route.name === 'Home')?_c('li',[_vm._m(0)]):_vm._e(),(
        ['step2', 'step3', 'step4', 'step5', 'booknow', 'success'].includes(
          _vm.$route.name
        )
      )?_c('li',[_c('div',{staticClass:"answer"},[_c('div',{on:{"click":_vm.setStatusFalse}},[_c('img',{staticClass:"icon",attrs:{"src":require("../assets/img/icons/location.svg")}}),_c('router-link',{attrs:{"to":"/"}},[_vm._v(_vm._s(_vm.getAirport)+" Airport")])],1)])]):_vm._e(),(_vm.$route.name === 'step2' && !_vm.getDepartDate)?_c('li',[_vm._m(1)]):_vm._e(),(_vm.$route.name === 'step2' && _vm.getDepartDate)?_c('li',[_c('div',{staticClass:"answer",on:{"click":_vm.setStatusFalse}},[_c('div',[_c('img',{staticClass:"icon",attrs:{"src":require("../assets/img/icons/departure.svg")}}),_c('router-link',{attrs:{"to":"/step2"}},[_vm._v(_vm._s(_vm.getDepartDate))])],1)])]):_vm._e(),(_vm.$route.name === 'step2' && _vm.getDepartDate)?_c('li',[_vm._m(2)]):_vm._e(),(
        ['step3', 'step4', 'step5', 'booknow', 'success'].includes(
          _vm.$route.name
        )
      )?_c('li',[_c('div',{staticClass:"answer",on:{"click":_vm.setStatusFalse}},[_c('div',[_c('img',{staticClass:"icon",attrs:{"src":require("../assets/img/icons/departure.svg")}}),_c('router-link',{attrs:{"to":"/step2"}},[_vm._v(_vm._s(_vm.getDepartDate))])],1)])]):_vm._e(),(['step4', 'step5', 'booknow', 'success'].includes(_vm.$route.name))?_c('li',[_c('div',{staticClass:"answer",on:{"click":_vm.setStatusFalse}},[_c('div',[_c('img',{staticClass:"icon",attrs:{"src":require("../assets/img/icons/arrival.svg")}}),_c('router-link',{attrs:{"to":"/step2"}},[_vm._v(_vm._s(_vm.getReturnDate))])],1)])]):_vm._e(),(_vm.$route.name === 'step4')?_c('li',[_vm._m(3)]):_vm._e(),(['step5', 'booknow', 'success'].includes(_vm.$route.name))?_c('li',[_c('div',{staticClass:"answer"},[_c('div',{on:{"click":_vm.setStatusFalse}},[_c('img',{staticClass:"icon",attrs:{"src":require("../assets/img/icons/people.svg")}}),_c('router-link',{attrs:{"to":"/step4"}},[_vm._v(_vm._s(_vm.$store.state.bookingParams.sleeps))])],1)])]):_vm._e(),(['booknow', 'success'].includes(_vm.$route.name))?_c('li',[_c('div',{staticClass:"answer"},[_c('div',{on:{"click":_vm.setStatusFalse}},[_c('img',{staticClass:"icon",attrs:{"src":require("../assets/img/icons/bedtime-white.svg")}}),_c('router-link',{attrs:{"to":"/step5"}},[_vm._v(" "+_vm._s(_vm.selectedProduct.Name)+" "+_vm._s(_vm.selectedProduct.NonDiscPrice ? `(£${_vm.selectedProduct.NonDiscPrice.toFixed(2)})` : "")+" ")])],1)])]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"question"},[_c('div',[_vm._v("Which UK airport are you flying from?")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"question"},[_c('div',[_vm._v("What date are you flying on?")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"question"},[_c('div',[_vm._v("What date are you returning on?")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"question"},[_c('div',[_vm._v("How many people in your party?")])])
}]

export { render, staticRenderFns }