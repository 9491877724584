<template>
  <div class="productInfo">
    <div v-if="loading" class="loading">
      <img src="@/assets/img/loading.svg" />
    </div>
    <div v-else>
      <div class="text">
        <h1>{{ getProduct.name }}</h1>
        <div class="star-rating">
          <img
            v-for="n in getProduct.star_rating"
            v-bind:key="n"
            src="../assets/img/icons/star.svg"
          />
        </div>
        <p>{{ getProduct.tripappsellpoint1 }}</p>
        <p>{{ getProduct.hotel_facilities }}</p>
        <p>{{ getProduct.sellpoint_security }}</p>
        <p>{{ getProduct.convenient }}</p>
        <p>{{ getProduct.tripappaddonsellpoint }}</p>
        <div class="buttons">
          <span class="button" v-on:click="toggleMoreInfo" href>More info</span>
          <span class="button book" v-on:click="bookNow">Book Now</span>
        </div>
      </div>
      <div class="facilities">
        <ul>
          <li v-if="getProduct.wifi === 1">
            <img src="@/assets/img/facilities/fac-wifi.svg" />
          </li>
          <li v-if="getProduct.all_rooms_non_smoking === 1">
            <img src="@/assets/img/facilities/fac-non-smoking.svg" />
          </li>
          <li v-if="getProduct.q_bar === 1">
            <img src="@/assets/img/facilities/fac-bar.svg" />
          </li>
          <li v-if="getProduct.q_restaurant === 1">
            <img src="@/assets/img/facilities/fac-restaurant.svg" />
          </li>
          <li v-if="getProduct.q_roomservice === 1">
            <img src="@/assets/img/facilities/fac-room-service.svg" />
          </li>
          <li v-if="getProduct.q_swimming === 1">
            <img src="@/assets/img/facilities/fac-pool.svg" />
          </li>
          <li v-if="getProduct.q_fitness === 1">
            <img src="@/assets/img/facilities/fac-gym.svg" />
          </li>
        </ul>
      </div>
      <div class="hero">
        <div
          class="image"
          v-bind:style="{
            'background-image':
              'url(https://secure.holidayextras.co.uk' + heroPhoto + ')'
          }"
        ></div>
      </div>
      <img
        class="close"
        src="../assets/img/icons/close.svg"
        v-on:click="deactivateProductInfo"
      />
      <more-info v-if="showMoreInfo" :hotelInfo="getProduct" />
    </div>
  </div>
</template>

<script>
import { getProduct } from "@/api/holiday-extras/holiday-extra.js";
import moreInfo from "@/components/moreInfo.vue";
export default {
  name: "productInfo",
  components: {
    moreInfo
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  watch: {
    productCode: function() {
      // watch it
      this.loading = true;
      // console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      this.callProduct(this.type);
    }
  },
  data() {
    return {
      loading: true,
      type: "hotels",
      photos: {
        type: Array
      },
      heroPhoto: "",
      showMoreInfo: false
    };
  },
  computed: {
    getProduct() {
      return this.$store.getters.getStock[this.$store.getters.getAirport]
        .hotels[this.product.Code][0];
    },
    getAirport() {
      return this.$store.getters.getAirport;
    }
  },
  methods: {
    toggleMoreInfo() {
      this.showMoreInfo = true;
    },
    bookNow() {
      this.$emit("booknow", this.product);
    },
    deactivateProductInfo() {
      this.$parent.showProductInfo = false;
      this.$parent.selectedProduct = undefined;
    },
    callProduct() {
      var url =
        this.$store.state.hxUrls.product + this.product.Code + "/lite.js";
      console.log("URL: ", url);

      /* FIXME: This is hacked, there should be better solution for the photos
        The reason why it`s left like this is beause I need to sort out vuex first and then to get the right data trough it here so I don`t want to make double work!
     */

      getProduct(this.product.Code).then(response => {
        this.setProduct(response.API_Reply.Product);

        let isFastTrack =
          this.$store.getters.getStock[this.$store.getters.getAirport].hotels[
            this.product.Code
          ][0]._prodtype === "upgrades";

        this.photos = this.$store.getters.getStock[
          this.$store.getters.getAirport
        ].hotels[this.product.Code][0];

        if (!isFastTrack) {
          this.photos = this.photos.tripappimages;
          this.photos = this.photos.split(";");
          this.heroPhoto = this.photos[1];
        } else {
          this.heroPhoto = this.photos.add_on_image;
        }
        this.loading = false;
      });
    },
    setProduct(hxData) {
      this.$store.commit({
        type: "setProduct",
        hxData: hxData,
        productCode: this.product.code ? this.product.code : this.product.Code
      });
    }
  },
  mounted() {
    this.callProduct(this.type);
    this.loading = true;
  }
};
</script>

<style scoped lang="sass">
@import "../assets/sass/global.sass"
.loading
  display: flex
  justify-content: center
  align-items: center
  img
    width: 3rem
    // transform: translateY(-7rem)
.productInfo
  height: 55rem
  width: 100%
  z-inex: -1
  margin-bottom: -10rem
  position: relative
  > div
    height: 100%
    width: 100%
  .text
    position: absolute
    top: 3rem
    left: 3rem
    z-index: 99
    max-width: 50rem
    color: $white
    padding: 3rem
    h1
      font-size: 3rem
      font-weight: 600
      display: inline-block
      line-height: 3.6rem
      margin: 0 0 1rem 0
      padding: 0
      text-transform: capitalize
    .star-rating
      img
        width: 1.4rem
    p
      font-size: 1.6rem
      opacity: 0.7
      margin-bottom: 2rem
      line-height: 2rem
    .button
      color: $very-dark-grey
      border-radius: 0.6rem
      letter-spacing: 0.5px
      font-size: 1.4rem
      font-weight: 400
      padding: 1rem 2rem
      display: inline-block
      text-transform: uppercase
      border: 1px solid $white
      color: $white
      background: linear-gradient(to right, rgba($white,0.1), rgba($accent2,0.1))
      cursor: pointer
      &:hover
        background: rgba($white,0.15)
      &.book
        margin-left: 1rem
        background: linear-gradient(to right, rgba($accent2,0.7), rgba($accent3,0.5))
        &:hover
          background: linear-gradient(to right, rgba($accent2,1), rgba($accent3,1))
  .facilities
    position: absolute
    z-index: 999
    bottom: 10rem
    right: 6rem
    opacity: 0.7
    ul
      li
        width: 2rem
        margin-left: 2.4rem
        display: inline-block
        span
          display: none
  .hero
    mask: linear-gradient(to bottom,rgba(0,0,0,0), rgba(0,0,0,0.5),  rgba(0,0,0,0.7 ), rgba(0,0,0,0))
    width: 100%
    height: 100%
    .image
      width: 100%
      height: 100%
      mask-image: linear-gradient(to right,rgba(0,0,0,0), rgba(0,0,0,0.3), rgba(0,0,0,0.8))
      background-position: center
      background-repeat: no-repeat
      background-size: cover
  .close
    position: absolute
    top: 6rem
    right: 6rem
    opacity: 0.7
    width: 3rem
    height: 3rem
    cursor: pointer
    z-index: 99 9
    &:hover
      opacity: 1
</style>
