import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    random: "",
    step: "1",
    hxUrls: {
      hotels: "https://api.holidayextras.co.uk/sandbox/v1/hotel/",
      hotelsandparking: "https://api.holidayextras.co.uk/sandbox/v1/hotel/",
      parking: "https://api.holidayextras.co.uk/sandbox/carpark/LGW/",
      lounges: "https://api.holidayextras.co.uk/sandbox/v1/lounge/",
      fasttracks: "https://api.holidayextras.co.uk/sandboxv1/fasttrack/",
      product: "https://api.holidayextras.co.uk/sandbox/v1/product/"
    },
    bookingParams: {
      airportName: "",
      airportCode: "",
      airportSlug: "",
      departDate: "",
      returnDate: "",
      stayingDays: "",
      roomName: "",
      roomCode: "",
      sleeps: "",
      secondRoomType: "",
      adults: "",
      children: ""
    },
    hxInfo: {
      ABTANumber: "AF341",
      password: "HOTEL",
      token: "999999999",
      key: "hotels24seven",
      initials: "DRM"
    },
    hxData: {},
    loading: {
      hotels: true,
      hotelsandparking: true,
      parking: true,
      lounges: true,
      fasttracks: true
    }
  },
  mutations: {
    setHXToken(state, payload) {
      state.hxInfo.token = payload.token;
    },
    setBookingParams(state, payload) {
      state.bookingParams = payload.bookingParams;
    },
    setAirport(state, payload) {
      state.bookingParams.airportName = payload.airportName;
      state.bookingParams.airportCode = payload.airportCode;
      state.bookingParams.airportSlug = payload.airportSlug;
      // console.log("PARAMS UPDATED: ", state.bookingParams);
    },
    setDepartDate(state, payload) {
      state.bookingParams.departDate = payload.departDate;
      // console.log("PARAMS UPDATED: ", state.bookingParams);
    },
    setReturnDate(state, payload) {
      state.bookingParams.returnDate = payload.returnDate;
      // console.log("PARAMS UPDATED: ", state.bookingParams);
    },
    setStayingDays(state, payload) {
      state.bookingParams.stayingDays = payload.stayingDays;
    },
    setRoomType(state, payload) {
      state.bookingParams.roomName = payload.roomName;
      state.bookingParams.roomCode = payload.roomCode;
      state.random = payload.random;
      state.bookingParams.sleeps = payload.sleeps;
      state.bookingParams.adults = payload.adults;
      state.bookingParams.children = payload.children;
      // console.log('PARAMS UPDATED: ', state.bookingParams)
    },
    setHotels(state, payload) {
      if (!state.hxData[state.bookingParams.airportSlug]) {
        state.hxData[state.bookingParams.airportSlug] = {};
      }
      state.hxData[state.bookingParams.airportSlug].hotels = payload.hxData;
      state.loading.hotels = false;
      // console.log('HXDATA UPDATED: ', state.hxData)
    },
    setHotelsAndParking(state, payload) {
      if (!state.hxData[state.bookingParams.airportSlug]) {
        state.hxData[state.bookingParams.airportSlug] = {};
      }
      state.hxData[state.bookingParams.airportSlug].hotelsandparking =
        payload.hxData;
      state.loading.hotelsandparking = false;
      // console.log('HXDATA UPDATED: ', state.hxData)
    },
    setParking(state, payload) {
      if (!state.hxData[state.bookingParams.airportSlug]) {
        state.hxData[state.bookingParams.airportSlug] = {};
      }
      state.hxData[state.bookingParams.airportSlug].parking = payload.hxData;
      state.loading.parking = false;
      // console.log('HXDATA UPDATED: ', state.hxData)
    },
    setLounges(state, payload) {
      if (!state.hxData[state.bookingParams.airportSlug]) {
        state.hxData[state.bookingParams.airportSlug] = {};
      }
      state.hxData[state.bookingParams.airportSlug].lounges = payload.hxData;
      state.loading.lounges = false;
      // console.log('HXDATA UPDATED: ', state.hxData)
    },
    setFastTracks(state, payload) {
      if (!state.hxData[state.bookingParams.airportSlug]) {
        state.hxData[state.bookingParams.airportSlug] = {};
      }
      state.hxData[state.bookingParams.airportSlug].fasttracks = payload.hxData;
      state.loading.fasttracks = false;
      // console.log('HXDATA UPDATED: ', state.hxData)
    },
    resetLoading(state, payload) {
      state.loading = payload.loading;
    },
    setProduct(state, payload) {
      if (!state.hxData[state.bookingParams.airportSlug]) {
        state.hxData[state.bookingParams.airportSlug] = {
          hotels: {}
        };
      }

      if (!state.hxData[state.bookingParams.airportSlug].hotels) {
        state.hxData[state.bookingParams.airportSlug].hotels = {};
      }
      if (
        !state.hxData[state.bookingParams.airportSlug].hotels[
          payload.productCode
        ]
      ) {
        state.hxData[state.bookingParams.airportSlug].hotels[
          payload.productCode
        ] = {};
      }

      // console.log("PRODUCT CODE: ", payload.productCode);
      state.hxData[state.bookingParams.airportSlug].hotels[
        payload.productCode
      ] = payload.hxData;

      // console.log("PRODUCT ADDED: ", state.hxData);
    },
    setSelectedProduct(state, payload) {
      state.bookingParams.selectedProduct = payload.productData;
    }
  },
  getters: {
    hxInfo: state => {
      return state.hxInfo;
    },
    bookingParams: state => {
      return state.bookingParams;
    },
    getStock: state => {
      return state.hxData;
    },
    getAirport: state => {
      return state.bookingParams.airportSlug;
    },
    getSelectedProduct: state => {
      return state.bookingParams.selectedProduct;
    }
  },
  actions: {},
  modules: {}
});
