<template>
  <div class="content">
    <!-- <button class="test-button" @click="addSlidePerView">BUTTON</button> -->
    <div class="steps step1">
      <div
        v-for="(region, index) in regions"
        :key="'Region' + index"
        class="row"
      >
        <label>{{ region.name }}</label>
        <carousel
          :swiperSlideArray="region.airports"
          :swiperSlideClass="['product']"
          :swiperSlideObjectKey="'name'"
          :swiperClass="['products', 'swiper' + index]"
          :swiperUniqueClass="'swiper' + index"
          :swiperPageOrder="index"
          v-slot="{ slotData }"
        >
          <div
            to="/step2"
            class="product-link cursor-pointer"
            :class="[getSlugWithoutExtension(slotData.slug)]"
            @click.prevent="
              getAirport(
                slotData.name,
                getSlugWithoutExtension(slotData.slug),
                slotData.code
              )
            "
          >
            <img :src="'./img/airports/' + slotData.slug" />
            <!-- {{slotData.name}} -->
          </div>
        </carousel>
      </div>
    </div>
  </div>
</template>

<script>
import Carousel from "../carousel.vue";
import "swiper/swiper-bundle.css";

export default {
  name: "step1",
  components: {
    Carousel
  },

  data() {
    return {
      regions: [
        {
          name: "London",
          slug: "london",
          airports: [
            {
              name: "Gatwick",
              slug: "gatwick.svg",
              code: "LGW"
            },
            {
              name: "Heathrow",
              slug: "heathrow.svg",
              code: "LHR"
            },
            {
              name: "Stansted",
              slug: "stansted.svg",
              code: "STN"
            },
            {
              name: "Luton",
              slug: "luton.svg",
              code: "LTN"
            },
            {
              name: "London City",
              slug: "london-city.png",
              code: "LCA"
            }
          ]
        },
        {
          name: "North West",
          slug: "north-west",
          airports: [
            {
              name: "Manchester",
              slug: "manchester.svg",
              code: "MAN"
            },
            {
              name: "Liverpool",
              slug: "liverpool.svg",
              code: "LPL"
            }
          ]
        },
        {
          name: "North East",
          slug: "north-east",
          airports: [
            {
              name: "Newcastle",
              slug: "newcastle.svg",
              code: "NCL"
            },
            {
              name: "Leeds Bradford",
              slug: "leeds-bradford.svg",
              code: "LBA"
            },
            {
              name: "Doncaster Sheffield",
              slug: "doncaster-sheffield.svg",
              code: "DSA"
            },
            {
              name: "Teesside Iternational",
              slug: "teesside.svg",
              code: "MME"
            },
            {
              name: "Humberside",
              slug: "humberside.svg",
              code: "HUY"
            }
          ]
        },
        {
          name: "Midlands and Wales",
          slug: "midlands-and-wales",
          airports: [
            {
              name: "Bristol",
              slug: "bristol.svg",
              code: "BRS"
            },
            {
              name: "Birmingham",
              slug: "birmingham.svg",
              code: "BHX"
            },
            {
              name: "Cardiff",
              slug: "cardiff.svg",
              code: "CWL"
            },
            {
              name: "Norwich",
              slug: "norwich.svg",
              code: "NWI"
            },
            {
              name: "East Midlands",
              slug: "east-midlands.svg",
              code: "EMA"
            }
          ]
        },
        {
          name: "Scotland",
          slug: "scotland",
          airports: [
            {
              name: "Aberdeen",
              slug: "aberdeen.svg",
              code: "ABZ"
            },
            {
              name: "Glasgow Prestwick",
              slug: "glasgow-prestwick.svg",
              code: "GLA"
            },
            {
              name: "Edinburgh",
              slug: "edinburgh.svg",
              code: "EDI"
            },
            {
              name: "Glasgow International",
              slug: "glasgow-international.svg",
              code: "PIK"
            }
          ]
        },
        {
          name: "Northern Ireland",
          slug: "northern-ireland",
          airports: [
            // TODO: fali!!
            {
              name: "Belfast City (George Best)",
              slug: "belfast-city.svg",
              code: "BHD"
            },

            {
              name: "Belfast International",
              slug: "belfast-international.svg",
              code: "BFS"
            }
          ]
        },
        {
          name: "South East",
          slug: "south-east",
          airports: [
            // TODO: fali
            {
              name: "Southampton",
              slug: "southampton.svg",
              code: "SOU"
            },
            {
              name: "Southend",
              slug: "southend.svg",
              code: "SEN"
            }
          ]
        },
        {
          name: "South West",
          slug: "south-west",
          airports: [
            {
              name: "Bournemouth",
              slug: "bournemouth.svg",
              code: "BOH"
            },
            {
              name: "Exeter",
              slug: "exeter.png",
              code: "EXT"
            }
          ]
        }
      ]
    };
  },
  methods: {
    getSlugWithoutExtension(slug) {
      return slug.split(".")[0];
    },

    reachedEnd(region) {
      region.disableArrowsNext = false;
      region.disableArrowsPrev = true;
      this.arrowNextKey++;
      this.arrowPrevKey++;
    },

    reachedBeginning(region) {
      region.disableArrowsNext = true;
      region.disableArrowsPrev = false;
      this.arrowNextKey++;
      this.arrowPrevKey++;
    },

    slideNext(region, index) {
      const mySwipe = document.querySelector(".swiper" + index).swiper;
      mySwipe.slideNext(500, true);
      region.disableArrowsPrev = true;
      this.arrowPrevKey++;
    },

    slidePrev(region, index) {
      const mySwipe = document.querySelector(".swiper" + index).swiper;
      mySwipe.slidePrev(500, true);
      region.disableArrowsNext = true;
      this.arrowNextKey++;
    },

    getAirport(name, slug, code) {
      // COMMIT THE SELECTED AIRPORT
      this.$store.commit({
        type: "setAirport",
        airportName: name,
        airportSlug: slug,
        airportCode: code
      });

      /* updating local storage data */
      localStorage.setItem(
        `bookingParams${btoa(window.location.host)}`,
        JSON.stringify(this.$store.getters.bookingParams)
      );
      this.$router.push("/step2");
    }
  }
};
</script>

<style lang="sass">
@import "../../assets/sass/global.sass"
@import "swiper/swiper.less"


.product-link
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 100000;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    border: 1px solid rgba($white, 0.5);
    border-radius: 1rem;
    transition: all .3s ease;
    box-shadow: inset 0 0.5rem 5rem rgba($black, 0.1), 0 0.5rem 1rem rgba($black, 0.2)

    &:hover
      border: 1px solid rgba($white, 1)
      cursor: pointer
      transform: scale(1.05) translate(-48%, -48%);
      // width: 105%;
      // height: 105%;

    &.active
      border: 3px solid rgba($white, 1)

.product
  color: $white
  font-size: 2.6rem
  font-weight: 600
  text-align: center
  width: 30rem
  height: 19rem
  border-radius: 1rem
  // border: 1px solid rgba($white, 0.5)
  display: inline-block
  vertical-align: top
  margin-right: 1rem
  white-space: normal
  div,
  a
    display: flex
    align-items: center
    justify-content: center
    height: 100%
    padding: 0 3rem
    color: $white
    flex-direction: column
    h3
      font-size: 2.6rem
      margin: 0 0 0.5rem 0
      width: 100%
      display: block
    h4
      font-size: 1.6rem
      letter-spacing: 1px
      margin: 0
      font-weight: 400
      text-align: left
      display: block
      font-weight: 300
    // text-transform: uppercase
  // box-shadow: 0 0 3rem rgba(0,0,0,0.2)
  // &:hover
  //   border: 1px solid rgba($white, 1)
  //   cursor: pointer
  // &.active
  //   border: 3px solid rgba($white, 1)
  img
    max-height: 7rem
    max-width: 18rem
  &.southend
    img
      max-height: 11rem
  &.bournemouth,
  &.aberdeen,
  &.newcastle,
  &.belfast-international
    img
      max-height: 11rem

.test-button
  color: white

.aberdeen
  background: linear-gradient(135deg, rgba($aberdeen,0.3) 0%,rgba($aberdeen,0) 100%)
.belfast-international
  background: linear-gradient(135deg, rgba($belfast-international,0.3) 0%,rgba($belfast-international,0) 100%)
.birmingham
  background: linear-gradient(135deg, rgba($birmingham,0.3) 0%,rgba($birmingham,0) 100%)
.bournemouth
  background: linear-gradient(135deg, rgba($bournemouth,0.3) 0%,rgba($bournemouth,0) 100%)
.bristol
  background: linear-gradient(135deg, rgba($bristol,0.3) 0%,rgba($bristol,0) 100%)
.cardiff
  background: linear-gradient(135deg, rgba($cardiff,0.3) 0%,rgba($cardiff,0) 100%)
.doncaster-sheffield
  background: linear-gradient(135deg, rgba($doncaster-sheffield,0.3) 0%,rgba($doncaster-sheffield,0) 100%)
.edinburgh
  background: linear-gradient(135deg, rgba($east-midlands,0.3) 0%,rgba($east-midlands,0) 100%)
.east-midlands
  background: linear-gradient(135deg, rgba($edinburgh,0.3) 0%,rgba($edinburgh,0) 100%)
.gatwick
  background: linear-gradient(135deg, rgba($gatwick,0.3) 0%,rgba($gatwick,0) 100%)
.glasgow-prestwick
  background: linear-gradient(135deg, rgba($glasgow-prestwick,0.3) 0%,rgba($glasgow-prestwick,0) 100%)
.glasgow-international
  background: linear-gradient(135deg, rgba($glasgow-international,0.3) 0%,rgba($glasgow-international,0) 100%)
.heathrow
  background: linear-gradient(135deg, rgba($heathrow,0.3) 0%,rgba($heathrow,0) 100%)
.humberside
  background: linear-gradient(135deg, rgba($humberside,0.3) 0%,rgba($humberside,0) 100%)
.stansted
  background: linear-gradient(135deg, rgba($stansted,0.3) 0%,rgba($stansted,0) 100%)
.liverpool
  background: linear-gradient(135deg, rgba($liverpool,0.3) 0%,rgba($liverpool,0) 100%)
.leeds-bradford
  background: linear-gradient(135deg, rgba($leeds-bradford,0.3) 0%,rgba($leeds-bradford,0) 100%)
.luton
  background: linear-gradient(135deg, rgba($luton,0.3) 0%,rgba($luton,0) 100%)
.london-city
  background: linear-gradient(135deg, rgba($london-city,0.3) 0%,rgba($london-city,0) 100%)
.manchester
  background: linear-gradient(135deg, rgba($manchester,0.3) 0%,rgba($manchester,0) 100%)
.norwich
  background: linear-gradient(135deg, rgba($norwich,0.3) 0%,rgba($norwich,0) 100%)
.newcastle
  background: linear-gradient(135deg, rgba($newcastle,0.3) 0%,rgba($newcastle,0) 100%)
.southend
  background: linear-gradient(135deg, rgba($southend,0.3) 0%,rgba($southend,0) 100%)
.teesside
  background: linear-gradient(135deg, rgba($teesside,0.3) 0%,rgba($teesside,0) 100%)
</style>
