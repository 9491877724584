<template>
  <div class="home">
    <step1 />
  </div>
</template>

<script>
import step1 from "@/components/steps/step1.vue";

export default {
  components: {
    step1
  }
};
</script>

<style lang="sass">
@import "../assets/sass/global.sass"
.home
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 37.5rem
  overflow: auto

  @media screen and (max-width: 964px) and (min-width: 769px)
    left: 30%

  .content
    padding: 0
    position: absolute
    top: 7rem
    right: 0
    bottom: 0
    left: 0
    &.date
      padding: 4rem
    .steps
      height: 100%
      > div
        &:last-child
          margin-bottom: 4rem
          padding-bottom: 6rem
      .center
        display: flex
        height: 100%
    .row
      position: relative
      &:first-child
        padding-top: rem
    label
      font-size: 1.4rem
      color: $white
      margin: 3rem 0 0 3rem
      margin-left: 5%;
      display: inline-block
      text-transform: uppercase
    ul.products
      overflow: auto
      display: block
      white-space: nowrap
      margin: 0
      padding: 0 2rem 1.4rem 3rem
      li, .product
        box-shadow: inset 0 0.5rem 5rem rgba($black, 0.1), 0 0.5rem 1rem rgba($black, 0.2)
        color: $white
        font-size: 2.6rem
        font-weight: 600
        text-align: center
        width: 30rem
        height: 19rem
        border-radius: 1rem
        border: 1px solid rgba($white, 0.5)
        display: inline-block
        vertical-align: top
        margin-right: 1rem
        white-space: normal
        div,
        a
          display: flex
          align-items: center
          justify-content: center
          height: 100%
          padding: 0 3rem
          color: $white
          flex-direction: column
          h3
            font-size: 2.6rem
            margin: 0 0 0.5rem 0
            width: 100%
            display: block
          h4
            font-size: 1.6rem
            letter-spacing: 1px
            margin: 0
            font-weight: 400
            text-align: left
            display: block
            font-weight: 300
          // text-transform: uppercase
        // box-shadow: 0 0 3rem rgba(0,0,0,0.2)
        &:hover
          border: 1px solid rgba($white, 1)
          cursor: pointer
        &.active
          border: 3px solid rgba($white, 1)
        img
          max-height: 7rem
          max-width: 18rem
        &.southend
          img
            max-height: 11rem
        &.bournemouth,
        &.aberdeen,
        &.newcastle,
        &.belfast-international
          img
            max-height: 11rem
    .calendar
      height: calc(100% - 4rem)
      width: 100%
      max-width: 64rem
      margin: 0 auto
      .vdp-datepicker__calendar
        > div
          height: 100%
          width: 100%
        height: 100%
        border: none
        width: 100%
        // max-width: 580px
        border-radius: 5px
        background: none
        header
          font-size: 1.8rem
          color: $white
          text-transform: uppercase
          height: 12.5%
          line-height: normal
          display: flex
          align-items: center
          justify-content: center
          span
            &:hover
              background: none
            &.next
              &:after
                border-left: 10px solid $white
            &.prev
              &:after
                border-right: 10px solid $white

        .cell
          font-size: 1.6rem
          font-weight: 600
          color: $white
          margin-right: 2px
          margin-bottom: 2px
          line-height: normal
          height: 12.5%
          width: calc(14.2857% - 2px)
          border: none
          display: flex
          align-items: center
          justify-content: center
          float: left
          &.day-header
            background: rgba($white, 0.1)
          &.day
            background: rgba($white, 0.2)
            &:hover
              border: none
              background: rgba($white, 0.25)
            &.blank,
            &.disabled
              background: rgba($white, 0.1)
              &:hover
                background: rgba($white, 0.1)
</style>
