<template>
  <div class="swiper-wrapper" ref="swiper-wrapper">
    <swiper
      class="swiper"
      :class="swiperClass"
      :options="swiperOption"
      :key="swiperOption.slidesPerView"
      @reach-end="reachedEnd()"
      @reach-beginning="reachedBeginning()"
    >
      <swiper-slide
        v-for="swiperSlide in swiperSlideArray"
        :key="'SwiperSlide' + swiperSlide[swiperSlideObjectKey]"
        :id="step5id ? swiperSlide[step5id] : ''"
        :class="swiperSlideClass"
      >
        <slot :slotData="swiperSlide" />
      </swiper-slide>
    </swiper>
    <div
      class="swiper-button swiper-button-prev"
      slot="button-prev"
      v-show="disableArrowsPrev || swiperOption.loop"
      :key="'Prev' + arrowPrevKey"
      @click="slidePrev()"
    >
      <img src="../assets/img/icons/chevron-left.svg" />
    </div>
    <div
      class="swiper-button swiper-button-next"
      slot="button-next"
      v-show="disableArrowsNext || swiperOption.loop"
      :key="'Next' + arrowNextKey"
      @click="slideNext()"
    >
      <img src="../assets/img/icons/chevron-right.svg" />
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
// import chevronRight from "../assets/img/icons/chevron-right.svg";

export default {
  name: "carousel",
  props: {
    swiperSlideArray: {
      type: Array,
      required: true
    }, //array of swiper cards
    swiperSlideClass: {
      type: Array,
      required: false
    }, //array of swiper slide classes
    swiperSlideObjectKey: {
      type: String,
      required: true
    }, //objKey is used like obj[key] for a swiper slide key
    swiperClass: {
      type: Array,
      required: true
    }, //array of swiper classes
    swiperOptions: {
      type: String,
      required: false
    }, //If not passed the defaults will be used
    swiperUniqueClass: {
      type: String,
      required: true
    }, //Unique class for swiper to be recognized
    swiperPageOrder: {
      type: Number,
      required: true
    }, //if you have multiple swipers on page each must have some unique PageOrderNumber
    swiperSlideWidth: {
      type: Number,
      default: 300
    }, //represents value in pixels
    step5id: {
      type: String,
      required: false
    }
  },

  components: {
    Swiper,
    SwiperSlide
    // chevronRight
  },

  created() {
    if (this.swiperOptions) {
      this.swiperOption = this.swiperOptions;
    }
  },

  mounted() {
    window.addEventListener("resize", this.onResize);
    this.onResize();
  },

  beforeDestroy() {
    // Unregister the event listener before destroying this Vue instance
    window.removeEventListener("resize", this.onResize);
  },

  data() {
    return {
      disableArrowsPrev: true,
      disableArrowsNext: true,
      arrowNextKey: 1,
      arrowPrevKey: 100,
      swiperOption: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 15,
        simulateTouch: true, //scroll effect
        watchOverflow: true,
        loop: false,
        loopFillGroupWithBlank: false,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          hideOnClick: true
        }
      }
    };
  },

  methods: {
    onResize() {
      let selector = this.$refs["swiper-wrapper"];
      const selectorWidth = selector.clientWidth;
      const screenWidth = document.querySelector("body").clientWidth;
      const cardsOnScreen = Math.round(selectorWidth / this.swiperSlideWidth);

      this.swiperOption.slidesPerView = cardsOnScreen;
      this.swiperOption.slidesPerGroup = cardsOnScreen;
      this.swiperOption.simulateTouch = screenWidth <= 768;

      this.disableArrowsPrev = false;
      if (this.swiperOption.slidesPerView >= this.swiperSlideArray.length) {
        this.disableArrowsPrev = false;
        this.disableArrowsNext = false;
      } else {
        this.disableArrowsNext = true;
      }
    },

    reachedEnd() {
      this.disableArrowsNext = false;
      this.disableArrowsPrev = true;
      this.arrowNextKey++;
      this.arrowPrevKey++;
    },

    reachedBeginning() {
      this.disableArrowsNext = true;
      this.disableArrowsPrev = false;
      this.arrowNextKey++;
      this.arrowPrevKey++;
    },

    slideNext() {
      const mySwipe = document.querySelector("." + this.swiperUniqueClass)
        .swiper;
      mySwipe.slideNext(500, true);
      this.disableArrowsPrev = true;
      this.arrowPrevKey++;
    },

    slidePrev() {
      const mySwipe = document.querySelector("." + this.swiperUniqueClass)
        .swiper;
      mySwipe.slidePrev(500, true);
      this.disableArrowsNext = true;
      this.arrowNextKey++;
    }
  }
};
</script>

<style lang="scss" scoped>
.swiper-wrapper {
  width: 100%;
  overflow: hidden;
  padding: 2rem 0;
}

.swiper {
  width: 90%;
  overflow: visible !important;
}

.swiper-button {
  height: 100%;
  width: calc(5% - 15px);
  bottom: 2rem; //compensate for .row padding-bottom which allows for 2rem enlarge on hover
  transition: all 0.3s ease;
  /*opacity: 0.25;*/
  border-radius: 1rem;
  overflow: hidden;
  height: 19rem;
  top: unset;

  &.swiper-button-next,
  &.swiper-button-prev {
    &:after {
      font-size: 2rem;
    }
  }

  &:hover {
    opacity: 1;
  }

  &:after {
    display: none;
  }

  &.swiper-button-next {
    right: 0;
  }

  &.swiper-button-prev {
    left: 0;
  }

  img {
    path {
      fill: white;
      border: 1px solid red;
    }
  }
}
</style>
