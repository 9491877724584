var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"steps step4"},_vm._l((Object.values(_vm.rooms)),function(room,index){return _c('div',{key:room.label,staticClass:"row"},[_c('label',[_vm._v(_vm._s(room.label))]),_c('carousel',{attrs:{"swiperSlideArray":Object.values(room.variants),"swiperClass":['products', 'swiper' + index],"swiperUniqueClass":'swiper' + index,"swiperSlideClass":['product'],"swiperSlideObjectKey":'code',"swiperPageOrder":index,"swiperSlideWidth":300},scopedSlots:_vm._u([{key:"default",fn:function({ slotData }){return [_c('div',{staticClass:"product-link",on:{"click":function($event){return _vm.getRoomType(
              slotData.name,
              slotData.code,
              slotData.sleeps,
              slotData.adults,
              slotData.children
            )}}},[_c('img',{attrs:{"src":'./roomTypes/room' + slotData.code + '.svg'}})])]}}],null,true)})],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }