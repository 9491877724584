<template>
  <div class="content date">
    <div class="steps step3">
      <div class="center">
        <datepicker
          :inline="true"
          :full-month-name="true"
          v-on:selected="getReturnDate"
          class="calendar"
          :disabled-dates="disabledDates"
        ></datepicker>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
export default {
  name: "step3",
  components: {
    Datepicker
  },
  data() {
    return {
      disabledDates: {
        to: new Date(this.$store.state.bookingParams.departDate)
      }
    };
  },
  computed: {
    getDepartDate() {
      return this.$store.state.bookingParams.departDate;
    }
  },
  methods: {
    getReturnDate(value) {
      this.$store.commit({
        type: "setReturnDate",
        returnDate: value
      });
      this.$router.push({ name: "step4" });
    }
  }
};
</script>
<style scoped lang="sass"></style>
