import Axios from "./axios-wrapper";
import store from "@/store/";
import moment from "moment";

export function getToken() {
  return Axios({
    url: `/usertoken/lite.js`,
    method: "GET",
    params: {
      key: "hotels24seven"
    }
  });
}

export function getHotels(requestParams) {
  let params = {
    ArrivalDate: moment(store.getters.bookingParams.departDate).format(
      "YYYY-MM-DD"
    ),
    RoomType: store.getters.bookingParams.roomCode
  };

  Object.assign(params, requestParams);

  return Axios({
    url: `/v1/hotel/${store.state.bookingParams.airportCode}/lite.js`,
    method: "GET",
    params: params
  });
}

export function getParkings(requestParams) {
  // TODO: Rename departDate to startDate and returnDate to endDate.
  let params = {
    ArrivalDate: moment(store.getters.bookingParams.departDate).format(
      "YYYY-MM-DD"
    ),
    DepartDate: moment(store.getters.bookingParams.returnDate).format(
      "YYYY-MM-DD"
    )
  };

  if (requestParams) {
    Object.assign(params, requestParams);
  }

  return Axios({
    url: `/v1/carpark/${store.state.bookingParams.airportCode}/lite.js`,
    method: "GET",
    params: params
  });
}

export function getLounges(requestParams) {
  //  TODO: For now we use static date, and we can ask time after they click time
  let params = {
    ArrivalDate: moment(store.getters.bookingParams.departDate).format(
      "YYYY-MM-DD"
    ),
    Adults: store.getters.bookingParams.adults,
    Children: store.getters.bookingParams.children,
    ArrivalTime: "12:00"
  };

  if (requestParams) {
    Object.assign(params, requestParams);
  }

  return Axios({
    url: `/v1/lounge/${store.getters.bookingParams.airportCode}/lite.js`,
    method: "GET",
    params: params
  });
}

export function getFastTrack(requestParams) {
  //  TODO: For now we use static date, and we can ask time after they click time
  let params = {
    ArrivalDate: moment(store.getters.bookingParams.departDate).format(
      "YYYY-MM-DD"
    ),
    Adults: store.getters.bookingParams.adults,
    Children: store.getters.bookingParams.children,
    ArrivalTime: "12:00"
  };

  if (requestParams) {
    Object.assign(params, requestParams);
  }

  return Axios({
    url: `/v1/fasttrack/${store.state.bookingParams.airportCode}/lite.js`,
    method: "GET",
    params: params
  });
}

export function getProduct(productCode, requestParams) {
  let params = {
    ArrivalDate: moment(store.getters.bookingParams.departDate).format(
      "YYYY-MM-DD"
    ),
    Nights: store.getters.bookingParams.stayingDays,
    RoomType: store.getters.bookingParams.roomCode,
    ParkingDays: store.getters.bookingParams.stayingDays
  };

  if (requestParams) {
    Object.assign(params, requestParams);
  }

  return Axios({
    url: `/v1/product/${productCode}/lite.js`,
    method: "GET",
    params: params
  });
}

export function hxBooking(productCode, params = {}) {
  /* I'm getting no availability error in this format */
  // const bookingUrl = `/v1/hotel/${productCode}/lite.js`;

  /* removing sandbox at the start since our base url
   * already included sandbox if the app is in test mode
   *
   * this url came from product data
   */
  const bookingUrl = store.getters.bookingParams.selectedProduct.BookingURL.replace(
    /(^\/sandbox)/gi,
    ""
  );

  return Axios({
    url: bookingUrl,
    method: "POST",
    params: params
  });
}
