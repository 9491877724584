<template>
  <div class="content date">
    <div class="steps step2">
      <datepicker
        :inline="true"
        :full-month-name="true"
        v-on:selected="getDepartDate"
        class="calendar"
        :disabled-dates="disabledDates"
      ></datepicker>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Datepicker from "vuejs-datepicker";
import moment from "moment";

export default {
  name: "step2",
  components: {
    Datepicker
  },
  mounted() {
    this.$store.commit({
      type: "setDepartDate",
      departDate: null
    });

    this.$store.commit({
      type: "setReturnDate",
      returnDate: null
    });
  },
  data() {
    return {
      disabledDates: {
        to: new Date()
      }
    };
  },
  methods: {
    // testHover(value) {
    //   // console.log("ovde", value);
    // },
    getDepartDate(value) {
      if (!this.bookingParams.departDate) {
        this.$store.commit({
          type: "setDepartDate",
          departDate: value
        });

        this.disabledDates = {
          to: new Date(this.$store.state.bookingParams.departDate)
        };
      } else {
        this.$store.commit({
          type: "setReturnDate",
          returnDate: value
        });

        let departMoment = moment([
          this.bookingParams.departDate.getFullYear(),
          this.bookingParams.departDate.getMonth(),
          this.bookingParams.departDate.getDate()
        ]);

        let arrivalMoment = moment([
          this.bookingParams.returnDate.getFullYear(),
          this.bookingParams.returnDate.getMonth(),
          this.bookingParams.returnDate.getDate()
        ]);

        this.$store.commit({
          type: "setStayingDays",
          stayingDays: arrivalMoment.diff(departMoment, "days")
        });

        /* updating local storage data */
        localStorage.setItem(
          `bookingParams${btoa(window.location.host)}`,
          JSON.stringify(this.$store.getters.bookingParams)
        );
        this.$router.push({ name: "step4" });
      }
    }
  },

  computed: {
    ...mapGetters(["bookingParams"])
  }
};
</script>

<style scoped lang="sass">
@import "../../assets/sass/global.sass"
</style>
