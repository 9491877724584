// This function checks the hostname and returns the environment
/**
 * @return {string}
 */
export default function DetectEnvironment() {
  let host = window.location.hostname;

  if (host === "localhost") return "local";
  if (host.includes("local")) return "local";

  if (host.includes("staging")) return "staging";
  if (host.includes("h247.monochromedns.co.uk")) return "staging";
  if (process.env.NODE_ENV !== "production") return "staging";

  return "production";
}
