export default async function EmailJS(params = {}, template = "CONTACT_FORM") {
  let serviceID = "gmail";
  let publicKey = "user_nRu9aKYKWfR7pFTE7tFmg";

  /* JECK ACCOUNT */
  if (template === "JECK") {
    serviceID = "service_re1ykci";
    publicKey = "dVXIdX0dGTqHB-9iF";
  }

  const emailTemplates = {
    ERROR: "error",
    HX_FAILED: "error",
    PAYMENT_FAILED: "error",
    SUCCESS: "confirmation",
    CONTACT_FORM: "template_3cWDtiiY",
    JECK: "sample_template"
  };

  let results;
  await window.emailjs
    .send(serviceID, emailTemplates[template], params, publicKey)
    .then(
      response => {
        results = response;
      },
      function(error) {
        results = error;
      }
    );

  return results;
}
