<template>
  <div class="home">
    <step5 />
  </div>
</template>

<script>
import step5 from "@/components/steps/step5.vue";
export default {
  components: {
    step5
  },
  methods: {
    checkData() {
      if (!this.$store.getters.getAirport) {
        this.$router.push({ name: "Home" });
      }
    }
  },
  mounted() {
    this.checkData();
  }
};
</script>

<style lang="sass"></style>
