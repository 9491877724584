<template>
  <nav>
    <h1>
      <router-link to="/"><strong>Hotels</strong>24Seven</router-link>
    </h1>
    <ul>
      <li v-if="$route.name === 'Home'">
        <div class="question">
          <div>Which UK airport are you flying from?</div>
        </div>
      </li>

      <li
        v-if="
          ['step2', 'step3', 'step4', 'step5', 'booknow', 'success'].includes(
            $route.name
          )
        "
      >
        <div class="answer">
          <div @click="setStatusFalse">
            <img class="icon" src="../assets/img/icons/location.svg" />
            <router-link to="/">{{ getAirport }} Airport</router-link>
          </div>
        </div>
      </li>
      <li v-if="$route.name === 'step2' && !getDepartDate">
        <div class="question">
          <div>What date are you flying on?</div>
        </div>
      </li>
      <li v-if="$route.name === 'step2' && getDepartDate">
        <div class="answer" @click="setStatusFalse">
          <div>
            <img class="icon" src="../assets/img/icons/departure.svg" />
            <router-link to="/step2">{{ getDepartDate }}</router-link>
          </div>
        </div>
      </li>
      <li v-if="$route.name === 'step2' && getDepartDate">
        <div class="question">
          <div>What date are you returning on?</div>
        </div>
      </li>

      <li
        v-if="
          ['step3', 'step4', 'step5', 'booknow', 'success'].includes(
            $route.name
          )
        "
      >
        <div class="answer" @click="setStatusFalse">
          <div>
            <img class="icon" src="../assets/img/icons/departure.svg" />
            <router-link to="/step2">{{ getDepartDate }}</router-link>
          </div>
        </div>
      </li>

      <li v-if="['step4', 'step5', 'booknow', 'success'].includes($route.name)">
        <div class="answer" @click="setStatusFalse">
          <div>
            <img class="icon" src="../assets/img/icons/arrival.svg" />
            <router-link to="/step2">{{ getReturnDate }}</router-link>
          </div>
        </div>
      </li>
      <li v-if="$route.name === 'step4'">
        <div class="question">
          <div>How many people in your party?</div>
        </div>
      </li>

      <li v-if="['step5', 'booknow', 'success'].includes($route.name)">
        <div class="answer">
          <div @click="setStatusFalse">
            <img class="icon" src="../assets/img/icons/people.svg" />
            <router-link to="/step4">{{
              $store.state.bookingParams.sleeps
            }}</router-link>
          </div>
        </div>
      </li>
      <li v-if="['booknow', 'success'].includes($route.name)">
        <div class="answer">
          <div @click="setStatusFalse">
            <img class="icon" src="../assets/img/icons/bedtime-white.svg" />
            <router-link to="/step5">
              {{ selectedProduct.Name }}
              {{
                selectedProduct.NonDiscPrice
                  ? `(&pound;${selectedProduct.NonDiscPrice.toFixed(2)})`
                  : ""
              }}
            </router-link>
          </div>
        </div>
      </li>
    </ul>
  </nav>
</template>

<script>
import moment from "moment";
export default {
  name: "sideBar",
  data() {
    return {};
  },
  methods: {
    setStatusFalse() {
      this.$emit("set-status-false");
    }
  },
  computed: {
    getAirport() {
      return this.$store.getters.getAirport;
    },
    getDepartDate() {
      if (this.$store.state.bookingParams.departDate) {
        return moment(this.$store.state.bookingParams.departDate).format(
          "dddd Do MMM YYYY"
        );
      }
      return false;
    },
    getReturnDate() {
      if (this.$store.state.bookingParams.returnDate) {
        return moment(this.$store.state.bookingParams.returnDate).format(
          "dddd Do MMM YYYY"
        );
      }
      return false;
    },
    selectedProduct() {
      return this.$store.getters.getSelectedProduct;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
@import "../assets/sass/global.sass"
nav
  width: 37.5rem
  box-shadow: 0 0 6rem rgba(0,0,0,0.2)
  z-index: 3
  min-height: 100vh
  background: rgba($white, 0.1)
  position: fixed
  top: 0
  left: 0
  padding: 0 3rem 3rem

  @media screen and (max-width: 964px) and (min-width: 769px)
    width: 30%


  h1
    height: 7rem
    line-height: 7rem
    text-align: center
    width: 100%
    font-size: 1.6rem
    font-weight: 400
    margin: 0 0 2rem 0
    display: block
    a
      color: $white

  &:after
    content: ""
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: auto
    width: 1px
    background: linear-gradient(to bottom, rgba($white, 0), rgba($white, 0.5), rgba($white, 0.7), rgba($white, 0.5), rgba($white, 0))
  &:before
    content: ""
    position: absolute
    top: 2rem
    right: 0
    height: 10rem
    width: 1px
    background: linear-gradient(to bottom, rgba($white, 0), rgba($white, 0.5), rgba($white, 1), rgba($white, 0.5), rgba($white, 0))
  ul
    li
      .question
        font-size: 3.2rem
        line-height: 4rem
        font-weight: 600
        color: $white
        position: relative
        > div
          padding: 3rem 3rem
          background: linear-gradient(to right, rgba($white,0.1), rgba($accent2,0.1))
          border: 5px solid $white
          border-radius: 1.4rem
          margin-top: 3rem
        &.last
          > div
            margin-top: 2rem
            background: $white
            color: $accent1
            box-shadow: 1rem 1rem 2rem rgba(0,0,0,0.05)
        &:after
          position: absolute
          top: 50%
          transform: translate(-50%, -50%) rotate(45deg)
          right: -1rem
          height: 2rem
          width: 2rem
          background: $accent1
          content: ""
          display: none
      .answer
        padding: 2rem
        font-size: 2rem
        display: flex
        // border-top: 1px solid rgba($white, 0.2)
        position: relative
        &:before
          position: absolute
          top: 0
          left: 0
          height: 1px
          width: 100%
          content: ""
          background: linear-gradient(to right, rgba($white, 0), rgba($white, 0.2), rgba($white, 0.4), rgba($white, 0.2), rgba($white, 0))
        div
          display: flex
        a
          color: $white
          font-weight: 600
          line-height: 3rem
          font-size: 1.6rem
          text-transform: capitalize
          &:hover
            text-decoration: underline
        .icon
          width: 3rem
          height: 3rem
          margin-right: 1.5rem
      &:first-child
        .answer
          &:before
            display: none
</style>
