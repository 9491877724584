import HxHost from "./HxHost.js";
import DetectEnvironment from "./DetectEnvironment.js";
import EmailJS from "./Email.js";
// This is your plugin object. It can be exported to be used anywhere.
const AppConfig = {
  // The install method is all that needs to exist on the plugin object.
  // It takes the global Vue object as well as user-defined options.
  install(Vue) {
    Vue.prototype.$app = {};
    Vue.prototype.$app.apiHost = HxHost();
    Vue.prototype.$app.env = DetectEnvironment();
    Vue.prototype.$app.sendEmail = async (
      params = {},
      template = "CONTACT_FORM"
    ) => {
      return await EmailJS(params, template);
    };
  }
};

export default AppConfig;
